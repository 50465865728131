<template>
  <div class="my-2">
    <vue-content-loading :width="750" :height="400"> </vue-content-loading>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: { VueContentLoading },
}
</script>

<style></style>